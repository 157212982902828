import axios from 'axios';
import Reflux from 'reflux';
import { client as graphql, Queries } from 'gql';
import { toast } from 'utils/Toast';
import { LoggerFactory } from 'logger';
import { getGtagContext } from 'analytics';
import AuthenticationError from 'error/AuthenticationError';
import ServiceError from 'error/ServiceError';
import Config from 'Config';
import AuthToken from 'model/AuthToken';
import User from 'model/User';
import Version from 'model/Version';
import { UserEvents } from 'analytics';

import getAuthenticationService from 'service/AuthenticationService';

const HTTP_TIMEOUT = 10000;

export const Actions = Reflux.createActions(['beginLogin', 'login', 'logout', 'expired', 'reload']);

export default class UserStore extends Reflux.Store {
  constructor() {
    super();

    this._logger = LoggerFactory.getLogger(this);
    this._apiUrl = Config.AUTH_URL;
    this._http = axios.create({
      timeout: HTTP_TIMEOUT,
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    });

    //set our auth token if it exists
    const authService = getAuthenticationService();
    this.state = {
      emailSent: false,
      user: null,
      loadingUser: false,
      authToken: authService.getToken(),
      errors: [],
    };

    this.listenables = Actions;
    this._initializeUser(this.state.authToken);
  }

  async _initializeUser(authToken, trackLoginEvent = false) {
    //if the auth token isn't set, the query will never succeed
    if (!authToken) {
      this._logger.debug('Auth token not set, skipping user query');

      if (this.state.loadingUser) {
        this.setState({ loadingUser: false });
      }

      return;
    }

    if (!this.state.loadingUser) {
      this.setState({ loadingUser: true });
    }

    this._logger.debug(`Querying GraphQL API with token: ${authToken}`);

    try {
      //now query the GraphQL api for the user
      const userAndVersion = await graphql.query({
        query: Queries.GET_USER_AND_VERSION,
      });
      const data = userAndVersion.data;
      const { major, minor, offset, branch, commit } = data.version;
      const version = Version.fromJSON({ major, minor, revision: offset, tag: branch || commit });
      const user = User.fromJSON(data.user);

      this._logger.debug(`User: ${user} loaded from GraphQL API v${version}`);

      //initialize gtag with the logged in user
      const gtag = getGtagContext();
      gtag.clientId = authToken.authorizationCode;
      gtag.userId = user.id.toString();
      gtag.companyId = user.companyId.toString();

      //track a login event
      if (trackLoginEvent) {
        UserEvents.userLoggedIn(user, authToken);
      }

      this.setState({ authToken, user, loadingUser: false });
    } catch (e) {
      const errors = this.state.errors;

      //if this is an authentication problem, will have received a 403,
      //otherwise something unexpected happened
      if (!e.networkError || e.networkError.statusCode !== 403) {
        this._logger.error(`Could not query user, an unexpected error occurred: ${e.message}`);
        errors.push(new ServiceError(e));
      } else {
        this._logger.error(`Authorization token is not valid or has expired`);
        errors.push(new AuthenticationError('Authorization token is not valid or has expired'));
      }

      const authService = getAuthenticationService();
      authService.clearToken();

      this.setState({ errors, loadingUser: false, authToken: null, user: null });

      toast(errors[0].message, 'error');
    }
  }

  async onBeginLogin(email) {
    this._logger.debug(`Sending login email to: '${email}'`);

    this.setState({ emailSent: true });

    const url = `${this._apiUrl}auth/`;

    try {
      const response = await this._http.post(url, { email });

      this._logger.debug(`Auth email succeeded with status: ${response.status}`);
    } catch (e) {
      this._logger.error(`An unexpected error occurred: ${e.message}`);

      const errors = this.state.errors;
      errors.push(new ServiceError(e));

      this.setState({ errors });
      toast('Could not send login email, an unexpected error occurred', 'error');
    }
  }

  async onLogin(authorizationCode) {
    this.setState({ errors: [], loadingUser: true });

    this._logger.debug(`Retrieving auth token from auth code: ${authorizationCode}`);

    const url = `${this._apiUrl}token/`;

    try {
      const response = await this._http.post(url, { authorizationCode });
      const authToken = AuthToken.fromJSON(response.data);
      const authService = getAuthenticationService();
      authService.setToken(authToken);

      await this._initializeUser(authToken, true);

      this._logger.debug(`User: ${this.state.user} logged in successfully`);
    } catch (e) {
      const errors = this.state.errors;

      //if this isn't an axios error, could be a network problem, or a parsing problem, etc
      if (!e.response) {
        this._logger.error(`An unexpected error occurred: ${e.message}`);
        errors.push(new ServiceError(e));
        return;
      }

      //if this fails with a 404, the code may no longer be valid
      if (e.response.status === 404) {
        this._logger.error(`Authorization code '${authorizationCode}' is not valid or has expired`);
        errors.push(new AuthenticationError('Authorization code is not valid or has expired'));
      } else {
        this._logger.error(`An error occurred attempting to retrieve user: ${e.message}`);
        errors.push(new ServiceError(e));
      }

      this.setState({ errors, loadingUser: false });

      toast(errors[0].message, 'error');
    }
  }

  async onLogout() {
    const authService = getAuthenticationService();
    authService.clearToken();

    //TODO: graphql api mutation - logout (for black list)

    this.setState({ authToken: null, user: null });

    this._logger.debug('User logged out');

    toast('User logged out successfully', 'info');

    //track a logout event
    UserEvents.userLoggedOut();
  }

  async onExpired() {
    const authService = getAuthenticationService();
    authService.clearToken();

    this.setState({ authToken: null, user: null });

    this._logger.debug('User session expired');

    toast('Your session has expired', 'info');

    //track a logout event
    UserEvents.userLoggedOut();
  }

  async onReload() {
    //now query the GraphQL api for the user
    const userAndVersion = await graphql.query({
      query: Queries.GET_USER_AND_VERSION,
      fetchPolicy: 'no-cache',
    });
    const data = userAndVersion.data;
    const user = User.fromJSON(data.user);
    const gtag = getGtagContext();
    gtag.clientId = this.state.authToken.authorizationCode;
    gtag.userId = user.id.toString();
    gtag.companyId = user.companyId.toString();
    this.setState({ authToken: this.state.authToken.authorizationCode, user });
  }
}
