import React, { useEffect, useState } from 'react';
import 'views/components/InfoPanel/children/TableStyles.scss';
import { Mutations } from 'gql';
import { useMutation } from 'react-apollo';
import InfraComponentItem from './InfraComponentItem';
import ProductEvents from 'analytics/ProductEvents';
import cn from 'classnames';

const InfraComponentTable = ({ projectId, phaseId, metro }) => {
  const [createCustomInfraComponent] = useMutation(Mutations.ADD_INFRA_COMPONENT_TO_METRO);
  const [infraComponentsState, setInfraComponentsState] = useState(metro.infraComponents);
  const [inputState, setInputState] = useState('');

  useEffect(() => {
    if (!metro.infraComponents) return;
    setInfraComponentsState(metro.infraComponents);
    setInputState('');
  }, [metro]);

  const addInfraComponent = () => {
    if (!inputState) return;
    createCustomInfraComponent({ variables: { phaseId, metroId: metro.id.value, name: inputState } });
    ProductEvents.infraComponentAdded(inputState);
    setInputState('');
  };

  return (
    <div className="table" headers={['InfraComponent', 'Actions']} data-testId="infraComponent-table">
      {infraComponentsState
        .sort((a, b) => {
          if (a.isCustom > b.isCustom) return 1;
          if (a.isCustom < b.isCustom) return -1;

          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
        .map((infraComponent) => {
          return (
            <React.Fragment key={`${metro.id.value}-${infraComponent.id}`}>
              <InfraComponentItem projectId={projectId} phaseId={phaseId} metro={metro} infraComponent={infraComponent} />
            </React.Fragment>
          );
        })}
      <div className="column add-item" data-testId="add-custom">
        <div className="item first container" title="Add a infraComponent">
          <input
            className="add-input"
            value={inputState}
            onChange={(e) => setInputState(e.target.value)}
            placeholder={'Type to add custom infraComponent...'}
          />
        </div>
        <div className="item last infraComponent-item">
          <button data-testId="add-icon" className={cn('add-button', { disabled: !inputState })} onClick={addInfraComponent} disabled={!inputState}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfraComponentTable;
