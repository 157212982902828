import React, { useEffect, useState } from 'react';
import 'views/components/InfoPanel/children/TableStyles.scss';
import { useMutation, useQuery } from 'react-apollo';
import { Mutations, Queries } from 'gql';
import { toast } from 'react-toastify';
import ExpandableHeader from './ExpandableHeader';
import DeviceInput from './DeviceInput';
import { DEPLOYMENT_TYPES } from 'model/deploymentTypes';
import ProductEvents from 'analytics/ProductEvents';

const InfraComponentItem = ({ projectId, phaseId, metro, infraComponent }) => {
  const { data: networkEdgeCategoriesData, error: errorEdgeCategories } = useQuery(Queries.GET_NETWORK_EDGE_CATEGORIES, {
    variables: { metroCode: metro?.code },
  });
  const { data: metalPlansData, error: metalPlansError } = useQuery(Queries.GET_METAL_PLANS, {
    variables: { metroCode: metro?.code },
  });
  const [enableInfraComponent, { data: enabledInfraComponent, loading: enabledInfraComponentLoading }] = useMutation(Mutations.ENABLE_INFRA_COMPONENT);
  const [disableInfraComponent] = useMutation(Mutations.DISABLE_INFRA_COMPONENT);
  const [deleteInfraComponent] = useMutation(Mutations.REMOVE_INFRA_COMPONENT);
  const [updateInfraComponent] = useMutation(Mutations.UPDATE_INFRA_COMPONENT);
  const [metalPlans, setMetalPlans] = useState([]);
  const [networkEdgeCategories, setNetworkEdgeCategories] = useState([]);
  const [infraComponentState, setInfraComponentState] = useState(infraComponent);

  useEffect(() => {
    errorEdgeCategories && toast("Couldn't fetch deployments", 'errorEdgeCategories');
    metalPlansError && toast("Couldn't fetch deployments", 'metalPlansError');
  }, [errorEdgeCategories, metalPlansError]);

  useEffect(() => {
    if (!networkEdgeCategoriesData) return;
    setNetworkEdgeCategories(networkEdgeCategoriesData.networkEdgeCategories);
  }, [networkEdgeCategoriesData]);

  useEffect(() => {
    if (!metalPlansData) return;
    setMetalPlans(metalPlansData.metalPlans);
  }, [metalPlansData]);

  useEffect(() => {
    if (!infraComponent) return;
    setInfraComponentState(infraComponent);
  }, [infraComponent]);

  useEffect(() => {
    const updateInfraComponent =
      (infraComponentState.isVisible !== infraComponent.isVisible ||
        infraComponentState.deployment !== infraComponent.deployment ||
        infraComponentState.device !== infraComponent.device) &&
      !enabledInfraComponentLoading;

    if (updateInfraComponent) {
      const device = infraComponentState.device ? infraComponentState.device : '';
      onUpdateInfraComponent(infraComponentState.deployment, device, infraComponentState.isVisible);
    }
    // eslint-disable-next-line
  }, [infraComponentState, enabledInfraComponentLoading]);

  const onEnabled = async () => {
    await enableInfraComponent({
      variables: { phaseId, metroId: metro.id.value, name: infraComponent.name },
      refetchQueries: [{ query: Queries.GET_PHASE, variables: { projectId, phaseId } }],
    });
    ProductEvents.infraComponentEnabled(infraComponent.name);
  };

  const onToggled = async () => {
    if (infraComponent.isEnabled) {
      await disableInfraComponent({ variables: { phaseId, metroId: metro.id.value, infraComponentId: infraComponent.id } });
      ProductEvents.infraComponentDisabled(infraComponent.name);
    } else onEnabled();
  };

  const onDeleteInfraComponent = async () => {
    await deleteInfraComponent({ variables: { phaseId, infraComponentId: infraComponent.id } });
    ProductEvents.infraComponentRemoved(infraComponent.id);
  };

  const onUpdateInfraComponent = async (deployment, device, visible) => {
    const infraComponentId = enabledInfraComponent ? enabledInfraComponent.enableInfraComponent.id : infraComponent.id;
    await updateInfraComponent({ variables: { phaseId, infraComponentId, deployment, device: device, visible: visible } });
    ProductEvents.infraComponentUpdated(infraComponentId);
  };

  const metalAvailable = !!metalPlans.length;
  const networkEdgeAvailable = !!networkEdgeCategories.length;
  const isExpandable =
    (infraComponent.deployment === DEPLOYMENT_TYPES.METAL.value || infraComponent.deployment === DEPLOYMENT_TYPES.EDGE.value) &&
    (metalAvailable || networkEdgeAvailable);

  const recommendedDevices = () => {
    if (infraComponent.deployment === 'COLOCATION') return null;

    let devices;

    if (infraComponent.deployment === 'METAL' && metalAvailable) {
      devices = metalPlans;
    } else if (infraComponent.deployment === 'EDGE' && networkEdgeAvailable) {
      if (infraComponent.deviceFilter) {
        const selectedDevice = networkEdgeCategories.find((device) => device.name === infraComponent.deviceFilter);
        devices = selectedDevice ? selectedDevice.devices : [];
      } else {
        devices = networkEdgeCategories.flatMap((device) => device.devices.map((device) => device));
      }
    } else {
      devices = null;
    }

    devices = devices?.filter(({ deprecated }) => !deprecated);

    return devices?.map(({ name }) => ({ value: name, label: name }));
  };

  return (
    <ExpandableHeader
      infraComponent={infraComponentState}
      setInfraComponentState={setInfraComponentState}
      isExpandable={isExpandable}
      onToggled={onToggled}
      onDelete={onDeleteInfraComponent}
      enableInfraComponent={onEnabled}
      metalAvailable={metalAvailable}
      networkEdgeAvailable={networkEdgeAvailable}>
      {infraComponent.isEnabled && isExpandable && (
        <DeviceInput infraComponent={infraComponentState} setInfraComponentState={setInfraComponentState} recommendedDevices={recommendedDevices} />
      )}
    </ExpandableHeader>
  );
};

export default InfraComponentItem;
