import React, { useState } from 'react';
import TextSelect from 'views/components/TextSelect';
import { filterDeploymentType, filterDeploymentTypes } from 'model/deploymentTypes';

const DeploymentSelect = ({ enableInfraComponent, infraComponent, setInfraComponentState, metalAvailable, networkEdgeAvailable }) => {
  const [deploymentType, setDeploymentType] = useState(
    filterDeploymentType(infraComponent.deployment || infraComponent.allowedDeployments[0], metalAvailable, networkEdgeAvailable)
  );

  const deploymentTypesOptions = filterDeploymentTypes(infraComponent.allowedDeployments, metalAvailable, networkEdgeAvailable);

  const handleChangeDeployment = (option) => {
    if (!infraComponent.isEnabled) enableInfraComponent();
    setDeploymentType(option);
    setInfraComponentState({ ...infraComponent, deployment: option.value });
  };

  const disableTextSelect = infraComponent.allowedDeployments.length === 0 || infraComponent.allowedDeployments.length === 1;

  return (
    <TextSelect
      // icon={deploymentType.icon('black', 16, 16)}
      label="via"
      options={deploymentTypesOptions}
      onChangeTextSelect={handleChangeDeployment}
      value={deploymentType}
      isClereable={false}
      isDeploymentSelect={true}
      placeholder={''}
      isDisabled={disableTextSelect}
    />
  );
};

export default DeploymentSelect;
